import { Box, Link, Typography } from "@mui/material";
import { FC } from "react";

export interface UnderConstructionPageProps {}

export const UnderConstructionPage: FC<UnderConstructionPageProps> = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#000",
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            color: "#fff",
            textAlign: "center",
            fontFamily: "Raleway, sans-serif",
            fontWeight: "300",
          }}
        >
          In
        </Typography>
        <Typography
          variant="h1"
          sx={{
            color: "#fff",
            textAlign: "center",
            fontFamily: "Raleway, sans-serif",
            fontWeight: "900",
          }}
        >
          Reach
        </Typography>
      </Box>
      <Link
        variant="body1"
        href="mailto:info@inreach.be"
        sx={{
          color: "#fff",
          textDecoration: "none",
          fontFamily: "Gotham Thin, sans-serif",
          textAlign: "center",
          "&:hover": { textDecoration: "none" },
        }}
      >
        info@inreach.be
      </Link>
    </Box>
  );
};
